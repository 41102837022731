
























import Vue from 'vue';
import { mapActions } from 'vuex';
import { ProductPriceRequest } from '../../types/requests';
import EmptyCanvas from '@/components/graphic/EmptyCanvas.vue';
import MainButton from '@/components/elements/MainButton.vue';
import RequestPriceModal from '@/components/modals/RequestPriceModal.vue';

export default Vue.extend({
  components: {
    EmptyCanvas,
    MainButton,
    RequestPriceModal,
  },

  data() {
    return {
      modalVisable: false,
      requestState: 'idle',
      errorCode: '',
    };
  },

  methods: {
    ...mapActions({
      searchQuery: 'products/searchQuery',
    }),
    async sendNewRequest(event: string): Promise<void> {
      this.requestState = 'pending';

      try {
        await this.$store.dispatch('requests/makeNewRequest', {
          requestText: event,
        } as ProductPriceRequest);

        this.requestState = 'success';
      } catch (e) {
        this.requestState = 'error';
        this.errorCode = e.message;
      }
    },

    showModal(): void {
      this.$modal.show('requestPrice', {
        preFilledText: this.$store.getters['products/getQuery'],
      });
    },
  },
});
