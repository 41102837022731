<template>
  <div>
    <!-- <SearchField
      class="mobile-search"
      :value="valueOfSearchQuery"
      @search-query="handleSearchQuery"
    /> -->

    <!-- MOBILE MENU -->

    <template v-if="isMobile">
      <div class="mobile-menu">
        <div class="mobile-menu-group">
          <h4 class="sub-heading">Velg varegruppe</h4>
          <SelectMenu :data="categories" :active="activeCategory" @change="changeActive" />
        </div>
        <div class="mobile-menu-group">
          <h4 class="sub-heading">Vis pris for</h4>
          <SelectMenu :data="priceYears" :active="priceYear" @change="changePriceYear" />
        </div>
      </div>
    </template>

    <!-- DESKTOP MENU -->

    <template v-if="!isMobile">
      <h4 class="sub-heading">Vis pris for</h4>
      <SideMenuItem
        v-for="year in priceYears"
        :key="year"
        :text="year"
        :active="priceYear === year ? true : false"
        @toggle-group="changePriceYear"
      />

      <h4 class="sub-heading">Velg varegruppe</h4>
      <template v-if="categories.length === 0">
        <PlaceholderSideMenuItem v-for="(i, index) in 10" :key="i" :groupEnd="checkIndex(index)" />
      </template>
      <template v-else>
        <SideMenuItem
          v-for="(category, index) in categories"
          :key="category"
          :text="category"
          :active="activeCategory === category ? true : false"
          :groupEnd="checkIndex(index)"
          @toggle-group="changeActive"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import SideMenuItem from '@/components/elements/SideMenuItem.vue';
import SelectMenu from '@/components/elements/SelectMenu.vue';
import PlaceholderSideMenuItem from '@/components/elements/PlaceholderSideMenuItem.vue';
// import SearchField from '@/components/elements/SearchField.vue';

export default {
  components: {
    SideMenuItem,
    SelectMenu,
    // SearchField,
    PlaceholderSideMenuItem,
  },

  computed: {
    ...mapState({
      categories: state => state.categories.all,
      activeCategory: state => state.categories.active,
      priceYear: state => state.products.priceYear,
      valueOfSearchQuery: state => state.products.query,
    }),
    ...mapGetters({
      priceYears: 'products/priceYears',
    }),
    isMobile() {
      if (this.$mq === 'tablet') return true;
      return false;
    },
  },

  beforeCreate() {
    this.$store.dispatch('categories/fetchCategories');
  },

  methods: {
    ...mapActions({
      searchQuery: 'products/searchQuery',
    }),

    changeActive(category) {
      if (this.activeCategory === category) {
        return false;
      }
      return this.$store.dispatch('categories/setActive', category);
    },

    changePriceYear(year) {
      if (this.priceYear === year) {
        return false;
      }
      return this.$store.dispatch('products/setPriceYear', year);
    },

    checkIndex(index) {
      const spaceIndex = [0, 4, 8, 12, 16];
      if (spaceIndex.includes(index)) {
        return true;
      }
      return false;
    },

    handleSearchQuery(query) {
      this.searchQuery(query);
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-heading {
  margin-bottom: $spacing-150;
}

.sub-heading:not(:first-child) {
  margin-top: $spacing-300;
}

.mobile-menu {
  display: flex;
  justify-content: space-between;

  .mobile-menu-group {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: $tablet) {
  .mobile-search {
    width: 100%;
  }
}
</style>
