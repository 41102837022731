import { mapState, mapGetters, mapActions } from 'vuex';
import ProductNotFound from '@/components/ProductNotFound.vue';
import ProductHeadline from '@/components/elements/ProductHeadline.vue';
import ProductCard from '@/components/elements/ProductCard.vue';
import MainButton from '@/components/elements/MainButton.vue';
import PlaceholderProductCard from '@/components/elements/PlaceholderProductCard.vue';
import Badge from '@/components/elements/Badge.vue';
import ExternalLink from '@/components/elements/ExternalLink.vue';
import fdvURL from '@/mixins/fdvURL';

export default {
  components: {
    ProductHeadline,
    ProductCard,
    MainButton,
    PlaceholderProductCard,
    Badge,
    ExternalLink,
    ProductNotFound,
  },

  mixins: [fdvURL],

  computed: {
    ...mapState({
      priceYear: state => state.products.priceYear,
      searchQuery: state => state.products.query,
      productsLoadedInStore: state => state.products.all,
    }),
    ...mapGetters({
      filteredProducts: 'products/filteredProducts',
      calculatePrice: 'products/calculatePrice',
    }),
  },

  methods: {
    ...mapActions({
      addToCart: 'cart/addItemToCart',
    }),
  },

  render(h) {
    // track current category during iteration
    let category = '';

    let badgeType = null;

    const badgeText = text => {
      if (text.avklares) {
        badgeType = 'danger';
        return 'Avklares';
      }
      if (text.standard) {
        badgeType = 'success';
        return 'Standard';
      }
      badgeType = null;
      return '';
    };

    // Renderfunction for productcards for use during iteration
    const productCardFactory = product =>
      h('ProductCard', {
        props: {
          productObj: {
            company: product.leverandor,
            name: product.navn,
            number: product.nrf,
            price: this.calculatePrice(product._key),
            tax: 'eks. mva.',
            info: product.kommentar,
          },
        },
        scopedSlots: {
          action: props =>
            h(
              'MainButton',
              {
                on: {
                  click: () =>
                    this.addToCart({
                      productId: product._key,
                      quantity: 1,
                      standard: product.standard,
                    }),
                },
              },
              'Legg til'
            ),
          badge: props => h('Badge', { props: { text: badgeText(product), type: badgeType } }),
          number: props =>
            h('ExternalLink', { props: { text: product.nrf, url: this.fdvURL(product.nrf) } }),
        },
        key: product._key,
      });

    // Array of renderfunctions for mockcards for loading indication
    const mockCardFactory = count => {
      const mockCardArray = [];
      for (let i = 0; i < count; i++) {
        mockCardArray.push([h(PlaceholderProductCard)]);
      }
      return mockCardArray;
    };

    // Show loader while fetching data
    if (this.filteredProducts.length === 0 && this.productsLoadedInStore.length === 0) {
      return h('div', mockCardFactory(10));
    }

    // Show no result if no search results
    if (this.filteredProducts.length === 0) {
      return h('ProductNotFound');
    }

    // Show filtered products
    return h(
      'div',
      this.filteredProducts.map(product => {
        if (product.kategori !== category) {
          category = product.kategori;
          return [
            h('ProductHeadline', { key: product.kategori }, product.kategori),
            productCardFactory(product),
          ];
        }
        return productCardFactory(product);
      })
    );
  },

  beforeCreate() {
    this.$store.dispatch('products/fetchProducts');
  },
};
