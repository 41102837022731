<template>
  <div class="search-container">
    <div class="search-field">
      <SearchIcon
        height="20px"
        width="20px"
        decorative="true"
        accentColor="#F5F7FA"
        mainColor="#9AA5B1"
      />
      <input
        :value="value"
        type="text"
        placeholder="Søk på navn, vare# eller leverandør..."
        class="search-input"
        @input="emitQuery($event.target.value)"
      />
      <button class="delete-button" @click="resetQuery" v-show="value.length !== 0">
        <CloseIcon />
      </button>
    </div>
  </div>
</template>

<script>
import SearchIcon from '@/components/graphic/SearchIcon.vue';
import CloseIcon from '@/components/graphic/CloseIcon.vue';

export default {
  components: {
    SearchIcon,
    CloseIcon,
  },

  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
  },

  methods: {
    resetQuery() {
      this.emitQuery('');
    },
    emitQuery(query) {
      this.$emit('search-query', query);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-container {
  display: inline-flex;
  align-items: flex-end;
  font-size: $font-size-2;
}

.search-field {
  display: flex;
  flex: 1 1 200px;
  align-items: center;
  border-bottom: 3px solid $color-gray-200;
  transition: all 0.2s ease;
}

.search-input {
  border: none;
  border-radius: 0px;
  outline: none;
  width: 100%;
}

.search-field:focus-within {
  border-bottom: 3px solid $color-gray-400;
  transition: all 0.2s ease;
}

.delete-button {
  font-size: $font-size--1;
  margin-left: $spacing-050;
}
</style>
