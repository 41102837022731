


















































import Vue from 'vue';
import MainButton from '@/components/elements/MainButton.vue';
import SecondaryButton from '@/components/elements/SecondaryButton.vue';
import Confirmation from '@/components/graphic/Confirmation.vue';
import Error from '@/components/graphic/Error.vue';

export default Vue.extend({
  components: {
    MainButton,
    SecondaryButton,
    Confirmation,
    Error,
  },

  props: {
    state: {
      type: String,
      required: false,
      default: 'idle',
      validator: value => {
        // The value must match one of these strings
        return ['idle', 'pending', 'success', 'error'].indexOf(value) !== -1;
      },
    },

    errorCode: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      requestText: '',
    };
  },

  methods: {
    beforeOpen(event: any): void {
      this.requestText = event.params.preFilledText;
    },

    emitRequestText(): void {
      this.$emit('request-text', this.requestText);
    },

    emitQueryReset(): void {
      if (this.state === 'success' || this.state === 'error') {
        this.$emit('reset-query');
      }
    },

    hideModal(): void {
      this.emitQueryReset();
      this.$modal.hide('requestPrice');
    },

    fallbackEmailContent(): string {
      return `subject=Prisforespørsel&body=Hei,%0d%0a%0d%0aDet skjedde en feil med kode ${this.errorCode} så derfor sender jeg forespørsel på epost i stedet :)%0d%0a%0d%0aØnsker pris på følgende varer:%0d%0a${this.requestText}`;
    },
  },
});
