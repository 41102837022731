<template functional>
  <div class="product-card">
    <!-- HEADER START -->
    <div class="product-card__header">
      <h3 class="product-card__header-text">{{ props.productObj.company }}</h3>
      <slot name="badge"></slot>
    </div>
    <!-- HEADER END -->
    <!-- BODY START -->
    <div class="product-card__body">
      <div class="product-card__product-info">
        <div class="product-card__product-name">
          {{ props.productObj.name }}
        </div>
        <div class="product-card__product-number">
          <slot name="number"></slot>
        </div>
      </div>
      <div class="product-card__product-action">
        <div>
          <div class="product-card__product-price">
            {{ props.productObj.price | currencyNOK }}
          </div>
          <div class="product-card__product-tax">
            {{ props.productObj.tax }}
          </div>
        </div>

        <div class="product-card__product-add">
          <slot name="action"></slot>
        </div>
      </div>
    </div>
    <!-- BODY END -->
    <div v-if="props.productObj.info" class="product-card__footer">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="14px">
        <path fill="#F0B429" d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"></path>
        <path
          fill="#FFFBEA"
          d="M11 12a1 1 0 0 1 0-2h2a1 1 0 0 1 .96 1.27L12.33 17H13a1 1 0 0 1 0 2h-2a1 1 0 0 1-.96-1.27L11.67 12H11zm2-4a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
        ></path></svg
      ><span class="product-card_footer-text">
        {{ props.productObj.info }}
      </span>
    </div>
  </div>
</template>

<script>
import fdvURL from '@/mixins/fdvURL';

export default {
  mixins: [fdvURL],
  props: {
    productObj: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.product-card,
.product-card__header,
.product-card__body,
.product-card__product-info,
.product-card__product-action {
  display: flex;
}

.product-card {
  margin-top: $spacing-150;
}

.product-card__header,
.product-card__body,
.product-card__product-action {
  justify-content: space-between;
}

.product-card__header,
.product-card__product-action {
  align-items: center;
}

.product-card,
.product-card__product-info {
  flex-direction: column;
}

.product-card__product-name,
.product-card__product-price {
  margin-bottom: $spacing-025;
}

.product-card__product-price {
  color: $color-main-900;
}

.product-card__product-number,
.product-card__product-tax,
.product-card__footer {
  font-size: $font-size--2;
  color: $color-gray-400;
}

.product-card__header,
.product-card__body,
.product-card__footer {
  padding-left: $spacing-150;
  padding-right: $spacing-150;
}

.product-card {
  border: 4px solid $color-gray-300;
  border-radius: $border-radius-small;
  background-color: $color-gray-bg;
}

.product-card__header {
  background-color: $color-gray-100;
  height: 54px;
}

.product-card__header-text {
  color: $color-gray-500;
  font-weight: 400;
  font-size: $font-size-1;
}

.product-card__body {
  flex-wrap: wrap;
}

.product-card__product-info {
  flex-basis: 320px;
}

.product-card__product-name,
.product-card__product-price {
  font-weight: 700;
}

.product-card__product-add {
  margin-left: $spacing-400;
}

.product-card__header,
.product-card__body,
.product-card__footer {
  margin-bottom: $spacing-150;
}

.product-card_footer-text {
  margin-left: $spacing-025;
}

@media screen and (max-width: 981px) {
  .product-card__product-info {
    margin-bottom: $spacing-200;
  }

  .product-card__product-action {
    width: 100%;
  }
}
</style>
