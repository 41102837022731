<template>
  <div class="container">
    <SideMenu />
    <div>
      <SearchField
        class="search"
        :value="valueOfSearchQuery"
        @search-query="handleSearchQuery"
      />
      <Products />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import SideMenu from '@/components/SideMenu.vue';
import Products from '@/components/Products';
import SearchField from '@/components/elements/SearchField.vue';

export default {
  components: {
    SideMenu,
    Products,
    SearchField,
  },

  computed: {
    ...mapGetters({
      valueOfSearchQuery: 'products/getQuery',
    }),
  },

  methods: {
    ...mapActions({
      searchQuery: 'products/searchQuery',
    }),
    handleSearchQuery(query) {
      this.searchQuery(query);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: grid;
  grid-template-columns: 200px auto;
  grid-column-gap: 48px;
}

.search {
  width: 100%;
  margin-bottom: $spacing-200;
}

@media screen and (max-width: $tablet) {
  .container {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-row-gap: 48px;
  }
}
</style>
