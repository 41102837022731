import { render, staticRenderFns } from "./PlaceholderSideMenuItem.vue?vue&type=template&id=a01de6a0&scoped=true&functional=true&"
import script from "./PlaceholderSideMenuItem.vue?vue&type=script&lang=js&"
export * from "./PlaceholderSideMenuItem.vue?vue&type=script&lang=js&"
import style0 from "./PlaceholderSideMenuItem.vue?vue&type=style&index=0&id=a01de6a0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "a01de6a0",
  null
  
)

export default component.exports