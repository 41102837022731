<template functional>
  <div :class="{ 'item-group': props.groupEnd }" class="placeholder">
    <div class="placeholder-text"></div>
  </div>
</template>

<script>
export default {
  props: {
    groupEnd: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 100%;
  animation: shine 2s infinite;
}

.placeholder-text {
  height: 30%;
  width: 75%;
  background-color: $color-gray-100;
  border-radius: 10px;
}

.item-group {
  margin-bottom: $spacing-150;
}
</style>
