<template>
  <div class="container">
    <router-view />
  </div>
</template>

<script>
export default {
  //
};
</script>

<style lang="scss" scoped>
.container {
  width: 90%;
  max-width: 1280px;
  margin: 3rem auto 0 auto;
  display: flex;
}
</style>
