<template functional>
  <a v-if="props.text !== ''" target="_blank" :href="props.url" class="external-link">
    {{ props.text }}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 24 24"
      class="icon-external-window"
    >
      <path
        class="primary"
        d="M12 8a1 1 0 0 1-1 1H5v10h10v-6a1 1 0 0 1 2 0v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9c0-1.1.9-2 2-2h6a1 1 0 0 1 1 1z"
      ></path>
      <path
        class="secondary"
        d="M19 6.41L8.7 16.71a1 1 0 1 1-1.4-1.42L17.58 5H14a1 1 0 0 1 0-2h6a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0V6.41z"
      ></path>
    </svg>
  </a>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.external-link {
  display: inline-flex;
  &:hover {
    color: $color-gray-600;
    transition: all 0.2;
  }
}
.icon-external-window {
  vertical-align: baseline;
  margin-left: $spacing-025;
}
.primary,
.secondary {
  fill: currentColor;
}
</style>
