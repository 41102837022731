<template functional>
  <div class="badge" :class="props.type">{{ props.text }}</div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'info',
      validator(value) {
        return ['success', 'warning', 'danger', 'info'].indexOf(value) !== -1;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.badge {
  padding: $spacing-025 $spacing-075;
  border-radius: 100px;
  color: $color-gray-100;
  font-size: $font-size--2;
  font-weight: 600;
  &.success {
    background-color: $color-green-600;
  }
  &.danger {
    background-color: $color-red-600;
  }
}
</style>
