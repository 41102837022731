<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :height="props.height"
    :width="props.width"
    :aria-hidden="props.decorative"
    aria-labelledby="title"
  >
    <title id="title">{{ props.title }}</title>
    <circle cx="10" cy="10" r="7" :fill="props.accentColor"></circle>
    <path
      :fill="props.mainColor"
      d="M16.32 14.9l1.1 1.1c.4-.02.83.13 1.14.44l3 3a1.5 1.5 0 0 1-2.12 2.12l-3-3a1.5 1.5 0 0 1-.44-1.14l-1.1-1.1a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
    ></path>
  </svg>
</template>

<script>
import iconProps from '@/mixins/iconProps';

export default {
  mixins: [iconProps],
};
</script>
