<template>
  <select class="dropdown" :value="this.active" @change="handleChange">
    <option v-for="d in data" :key="d" :value="d">{{ d }}</option>
  </select>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    active: {
      type: String,
      required: false,
      default: '',
    },
  },

  methods: {
    handleChange(e) {
      this.$emit('change', e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  font-size: inherit;
  color: inherit;
  padding: $spacing-075;
  border-radius: $border-radius-small;
  border: 1px solid $color-gray-200;
}
</style>
