<template functional>
  <button class="btn-main btn-lg btn-grad-dark" @click="listeners['click']">
    <slot></slot>
  </button>
</template>

<script>
export default {
  //
};
</script>

<style lang="scss" scoped>
.btn-main {
  border-radius: $border-radius-small;
  font-weight: 600;
}

.btn-lg {
  padding: $spacing-050 $spacing-100;
}

.btn-light {
  background-color: $color-main-300;
  color: $color-main-900;
  border-bottom: 3px solid $color-main-500;
  &:hover {
    background-color: $color-main-200;
    transition: all 0.2s;
  }
}

.btn-grad {
  background: linear-gradient(180deg, #ffffff -276.25%, $color-main-300 361.25%);
  color: $color-main-900;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: none;
    transition: all 0.2s;
  }
}

.btn-grad-dark {
  position: relative;
  z-index: 1;
  background: linear-gradient(180deg, $color-main-500 -63.75%, $color-main-800 126.25%);
  color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: none;
    transition: all 0.2s;
  }
}

.btn-grad-dark::before {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, $color-main-500 -63.75%, $color-main-800 160.5%);
  z-index: -1;
  transition: opacity 0.2s linear;
  opacity: 0;
  border-radius: $border-radius-small;
}

.btn-grad-dark:hover::before {
  opacity: 1;
}
</style>
