<template functional>
  <button
    class="button"
    :class="{ active: props.active, 'item-group': props.groupEnd }"
    @click="listeners['toggle-group'](props.text)"
  >
    {{ props.text }}
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
      defult: false,
    },
    groupEnd: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  padding: $spacing-050 $spacing-150;
  width: 100%;
  text-align: left;
  font-weight: 600;
  transition: all 0.3s;
  border-left: 5px solid transparent;
  border-radius: 4px;
  &:hover {
    color: $color-main-900;
    transition: all 0.3s;
  }
}

.active {
  background-color: $color-gray-200;
  border-left: 5px solid $color-main-900;
  color: $color-main-900;
}

.item-group {
  margin-bottom: $spacing-150;
}
</style>
