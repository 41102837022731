<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :height="props.height"
    :width="props.width"
    :aria-hidden="props.decorative"
    aria-labelledby="title"
  >
    <title id="title">{{ props.title }}</title>
    <path
      :fill="props.mainColor"
      fill-rule="evenodd"
      d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
    ></path>
  </svg>
</template>

<script>
import iconProps from '@/mixins/iconProps';

export default {
  mixins: [iconProps],
};
</script>
